export default [

  {
    path: '/amz-selling-partners',
    name: 'amz-selling-partners',
    component: () => import('@/views/pages/amz-selling-partners/AccountsList.vue'),
    meta: {
      pageTitle: 'AWS SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
      buttonAction:  [{
        title: 'General logs',
        route: 'amz-selling-partners-logs2',
        icon: 'ListIcon'
      }]
    },
  },
  {
    path: '/amz-selling-partners/edit/:accountId',
    name: 'edit-amz-selling-partners',
    component: () => import('@/views/pages/amz-selling-partners/EditAccount.vue'),
    meta: {
      pageTitle: 'SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-selling-partners/logs/:accountId',
    name: 'amz-selling-partners-logs',
    component: () => import('@/views/pages/amz-selling-partners/AccountLogs.vue'),
    meta: {
      pageTitle: 'SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Account Logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-selling-partners/logs',
    name: 'amz-selling-partners-logs2',
    component: () => import('@/views/pages/amz-selling-partners/AccountLogs.vue'),
    meta: {
      pageTitle: 'SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Account Logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-selling-partners/listings/:accountId',
    name: 'amz-selling-partners-listings',
    component: () => import('@/views/pages/amz-selling-partners/AccountActiveListings'),
    meta: {
      pageTitle: 'SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Account Active Listing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-selling-partners/listings-sync-history/:accountId',
    name: 'amz-selling-partners-listings-sync-history',
    component: () => import('@/views/pages/amz-selling-partners/AccountActiveListingsSyncHistory'),
    meta: {
      pageTitle: 'SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Account Active Listing Sync History',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-selling-partners/monitored/:accountId',
    name: 'amz-selling-partners-monitored',
    component: () => import('@/views/pages/amz-selling-partners/AccountMonitoredListings'),
    meta: {
      pageTitle: 'SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Account Monitored Listing',
          active: true,
        },
      ],
      buttonAction: [{
        title: 'Add monitored listing',
        route: 'add-monitored-listing',
        icon: 'PlusCircleIcon',
      }],
    },
  },
  {
    path: '/amz-selling-partners/monitored/:accountId/add',
    name: 'add-monitored-listing',
    component: () => import('@/views/pages/amz-selling-partners/AddMonitoredListing'),
    meta: {
      pageTitle: 'Monitored Listing',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Add Monitored Listing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-monitored-listing/:itemId',
    name: 'edit-monitored-listing',
    component: () => import('@/views/pages/amz-selling-partners/EditMonitoredListing'),
    meta: {
      pageTitle: 'Monitored Listing',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Edit Monitored Listing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-selling-partners',
    name: 'amz-selling-partners',
    component: () => import('@/views/pages/amz-selling-partners/AccountsList.vue'),
    meta: {
      pageTitle: 'AMZ SELLING PARTNERS',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-sp-reports-ranges/:accountId',
    name: 'amz-sp-reports-ranges',
    component: () => import('@/views/pages/amz-selling-partners/ReportsRanges.vue'),
    meta: {
      pageTitle: 'AMZ SP Reports',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Reports Ranges',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-sp-orders-ranges/:accountId',
    name: 'amz-sp-orders-ranges',
    component: () => import('@/views/pages/amz-selling-partners/OrdersRanges.vue'),
    meta: {
      pageTitle: 'AMZ SP Orders',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Orders Ranges',
          active: true,
        },
      ],
    },
  },
  {
    path: '/amz-sp-events-ranges/:accountId',
    name: 'amz-sp-events-ranges',
    component: () => import('@/views/pages/amz-selling-partners/EventsRanges.vue'),
    meta: {
      pageTitle: 'AMZ SP Events',
      resource: 'Page',
      breadcrumb: [
        {
          text: 'Events Ranges',
          active: true,
        },
      ],
    },
  },

]
