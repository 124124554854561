import axios from '@axios'

export default {

  fetchAll(context, page = 1) {
    return axios.get(`amz-selling-partners?page=${page}`)
  },

  getState(context) {
    return axios.get('amz-selling-partners/state')
  },

  get(context, id) {
    return axios.get(`amz-selling-partners/${id}`)
  },

  create(context, data) {
    return axios.post('amz-selling-partners', data)
  },

  remove(context, id) {
    return axios.delete(`amz-selling-partners/${id}`)
  },

  update(context, data) {
    return axios.put(`amz-selling-partners/${data.id}`, data)
  },

  fetchLogs(context, params) {
    let query = `?page=${params.page || 1}`

    if (params.key) {
      query += `&key=${params.key}`
    }

    if (params.status) {
      query += `&status=${params.status}`
    }

    if(params.accountId) {
      return axios.get(`amz-selling-partners/${params.accountId}/logs${query}`)

    } else {
      return axios.get(`amz-selling-partners/logs${query}`)

    }


  },

  fetchActiveListingsSyncHistory(context, params) {
    return axios.get(`amz-selling-partners/${params.accountId}/sync-listings-history`)
  },

  fetchListingParents(context, params) {
    return axios.get(`amz-selling-partners/${params.accountId}/listing-parents`)
  },

  updateListingItem(context, data) {
    return axios.put(`amz-sp-listings/${data.id}`, data)
  },

  fetchMonitoredItems(context, params) {
    return axios.get(`amz-sp-monitored-listings?accountId=${params.accountId}`)
  },

  getMonitoredItem(context, id) {
    return axios.get(`amz-sp-monitored-listings/${id}`)
  },

  createMonitoredItem(context, data) {
    return axios.post('amz-sp-monitored-listings', data)
  },

  updateMonitoredItem(context, data) {
    return axios.put(`amz-sp-monitored-listings/${data.id}`, data)
  },

  removeMonitoredItem(context, id) {
    return axios.delete(`amz-sp-monitored-listings/${id}`)
  },

  processMonitoredItem(context, data) {
    return axios.post(`amz-sp-monitored-listings/${data.id}/process`, data)
  },
  getMonitoredItemFeed(context, id) {
    return axios.get(`amz-sp-monitored-listings/feed/${id}`)
  },

}
